import React from 'react'
import '../../styles/bookErrorView.scss';
const ErrorView = props => {

    return(
        <div className = "error-view-container">
            <div className = "error-book-bg"/>
        </div>
    )
}

export default ErrorView;