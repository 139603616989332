const constants = {
  routes: {
    browseBooks: "/browsebooks",
    matchedBooks: "/matchedbooks",
    profile: "/profile",
    signin: "/signin",
    terms:"/terms-of-service",
    signup: "/signup",
    home: "/browsebooks",
    privacy:"/privacy-policy"
  },
  validRoutes: ["/browsebooks", "/matchedbooks", "/profile", "/browsebooks"],
  findBooksDistance: 3,
};

export default constants;
