import React from "react";
import Container from "react-bootstrap/Container";
import { LogoutOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import ListGroup from "react-bootstrap/ListGroup";
import "../styles/signup.scss";
import { useHistory } from "react-router-dom";

const Terms = (props) => {
  const history = useHistory();
  const routeChange = () => {
    history.goBack();
  };
  return (
    <div className="terms-root-container">
      <div className="logo-container">
        <div style={{ marginLeft: "0px", display: "flex" }}>
          <Tooltip title="Back">
            <Button
              type="text"
              onClick={routeChange}
              icon={<LogoutOutlined />}
              className="white-font"
            />
          </Tooltip>
        </div>
        Terms and Conditions
      </div>
      <div className="Login">
        <Container>
          <ListGroup as="ol" numbered className="list-group list-group-flush">
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">
                  Prohibited Content and Community Guidelines:
                </div>
                1. Our community is built on a foundation of mutual respect and
                common decency. As such, users are prohibited from adding any
                details about books that go against morality, including but not
                limited to content that is illegal, discriminatory, obscene,
                offensive or harmful to others.{"\n"}
                2. The platform reserves the right to remove any content that
                violates these terms and conditions, after issuing prior notice
                to the user.
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start  bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">User Responsibility:</div>
                1. Users are solely responsible for the accuracy and
                authenticity of the details they add about books. Users are
                prohibited from harassing, threatening, or intimidating others
                while using the platform, including but not limited to other
                users, employees, or representatives of the platform. {"\n"}
                2. Harassment includes, but is not limited to, the use of
                offensive language, racial or ethnic slurs, derogatory comments,
                or any other behaviour that is intended to harm or intimidate
                others.{"\n"}
                3. Users are prohibited from engaging in any activity that may
                harm the platform or other users, including but not limited to
                stalking, doxxing, sharing personal information without consent,
                hacking, spamming, or spreading malware. {"\n"}
                4. Users are prohibited from engaging in any activity that may
                cause harm to other users and are prohibited from using the
                platform for any illegal or unethical purposes.
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start  bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">Reporting Content:</div>
                1. If you encounter content on our platform that you believe is
                in violation of our terms of service or community guidelines,
                please report it to us immediately at support@thebookxchange.in.
                We will investigate all reports of prohibited content and take
                appropriate action as necessary.
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start  bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">Termination:</div>
                1. We reserve the right to terminate the accounts of users who
                violate our terms of service or community guidelines, or who
                otherwise engage in conduct that we deem to be detrimental to
                our community.
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </div>
    </div>
  );
};

export default Terms;
