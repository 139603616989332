import React from 'react'
import Home from './Home'
import '../styles/main.scss';
const Main = () => {
    return(
        <div className = "rootcontainer">
            <Home />
        </div>   
    )
}

export default Main;