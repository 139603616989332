import React,{useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown, Button } from "antd";
import { BellFilled } from "@ant-design/icons";
import "../../styles/styles.scss";
import "../../styles/layout.scss";
import "../../styles/ant.scss";
import API from "../../shared/api";
import handleApiError from "../../shared/errorhandler";
import notifyUser from "../../shared/Notification";
import { connect } from "react-redux";
import { updateNotifs } from "../../redux/profileSlice";

const mapStateToProps = (state) => ({
  notifications: state.profile.data.accountInfo.notifications,
  timestamp: state.profile.data.accountInfo.timestamp,
  email:state.profile.data.accountInfo.email,
});
const readNotifs = (timestamp, updateNotifs) => {
  const requestBody = {
    timestamp: timestamp,
  };
  const myApi = new API();
  myApi.endpoints.users
    .readNotifications(requestBody)
    .then((response) => {
      notifyUser(
        "success",
        "Notifications cleared!",
        "Notifications have been marked as read."
      );
      updateNotifs(response.data.data.newNotifications.notifications);
    })
    .catch((error) => {
      handleApiError(error);
    });
};

const Layout = (props) => {
  const { notifications, timestamp, email,updateNotifs } = props;
  let newNotifs = notifications === undefined ? [] : notifications;
  let history = useHistory();
  useEffect(() => {
    const interval = setInterval(() => {
      const myApi = new API();
      myApi.endpoints.users
        .fetchMyUser()
        .then((response) => {
          updateNotifs(response.data.data.notifications);
        })
        .catch((error) => {
          handleApiError(error);
        });
    }, 60000);
  
    return () => clearInterval(interval);
  }, [updateNotifs])
  const onNotificationClick = ({ item, key }) => {
    if (
      item !== undefined &&
      item.props.children[0][1].props.children !== "No New Notifications"
    ) {
      var sendEmail = item.props.children[0][1].props.children.match(/\(([^)]+)\)/);
      if (sendEmail !== null) {
        if(email.includes('gmail'))
        {
          window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${sendEmail[1]}&su=Exchanging%20Books&body=Hey!%20We%20matched%20on%20thebookxchange.%20It%20looks%20like%20we%20should%20exchange%20books.`)
        }
        if(email.includes('jgu')){
          window.open(`https://outlook.office.com/mail/deeplink/compose?subject=Exchanging%20Books&body=Hey!%20We%20matched%20on%20thebookxchange.%20It%20looks%20like%20we%20should%20exchange%20books.&to=${sendEmail[1]}`)
        }
      } else {
        history.push("/matchedbooks");
      }
    }
  };

  const menu = (
    <Menu onClick={onNotificationClick}>
      <Menu.ItemGroup title="Notifications">
        {newNotifs.length !== 0 ? (
          notifications.map((value) => (
            <Menu.Item key={value.userId}>{value.text}</Menu.Item>
          ))
        ) : (
          <Menu.Item>{"No New Notifications"}</Menu.Item>
        )}
      </Menu.ItemGroup>
      {newNotifs.length !== 0 ? (
        <Button
          type="secondary"
          style={{ backgroundColor: "#115173", color: "white" }}
          onClick={() => readNotifs(timestamp, updateNotifs)}
        >
          Mark as Read
        </Button>
      ) : null}
    </Menu>
  );

  return (
    <div className="full-container">
      <div className="mini-container" />
      <div className="layout-container">
        <div className="layout-inner-container">{props.children}</div>
      </div>
      <div className="mini-container">
        <div className="notification-container">
          <Dropdown overlay={menu} className="cursor-pointer">
            <BellFilled className="notification" />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default connect(mapStateToProps, { updateNotifs })(Layout);
