import React from "react";
import Container from "react-bootstrap/Container";
import { LogoutOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import ListGroup from "react-bootstrap/ListGroup";
import "../styles/signup.scss";
import { useHistory } from "react-router-dom";

const Privacy = (props) => {
  const history = useHistory();
  const routeChange = () => {
    history.goBack();
  };
  return (
    <div className="terms-root-container">
      <div className="logo-container">
        <div style={{ marginLeft: "0px", display: "flex" }}>
          <Tooltip title="Back">
            <Button
              type="text"
              onClick={routeChange}
              icon={<LogoutOutlined />}
              className="white-font"
            />
          </Tooltip>
        </div>
        Privacy Policy
      </div>
      <div className="Login">
        <Container>
          <div>We take your privacy seriously and want you to feel confident when using our website. This privacy policy explains how we collect, use, and protect your personal information, specifically your email address.</div>
          <ListGroup as="ol" numbered="true" className="list-group list-group-flush">
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">
                Information We Collect:
                </div>
                1. We only collect your email address when you sign up to our website to participate in book exchanges. We will never sell or share your email address with third parties.{"\n"}
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start  bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">How We Use Your Information:</div>
                1. We use your email address to send you notifications about book exchanges on our platform, as well as any important updates or announcements related to the service. We may also use your email address to contact you in case of any issues related to your account. {"\n"}
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start  bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">How We Protect Your Information:</div>
                1. We take appropriate technical and organizational measures to protect your personal information from loss, misuse, unauthorized access, disclosure, alteration, or destruction. Your email address is stored on secure servers and is only accessible to authorized personnel who need it to carry out their job duties.
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start  bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">Data Retention:</div>
                1. We will retain your email address for as long as your account is active on our website. If you wish to delete your account, you may do so by contacting us at the email address provided below.
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start  bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">Your Rights:</div>
                1. You have the right to access, correct, or delete any personal information we hold about you. If you wish to exercise any of these rights, please contact us at the email address provided below.
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start  bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">Changes to This Privacy Policy:</div>
                1. We reserve the right to update or change this privacy policy at any time. Any changes will be posted on our website, and your continued use of our website after such changes constitutes your acceptance of the new privacy policy.
              </div>
            </ListGroup.Item>
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start  bg-transparent"
            >
              <div className="ms-2 me-auto white">
                <div className="fw-bold Terms">Contact Us:</div>
                1. If you have any questions or concerns about this privacy policy, or if you wish to exercise any of your rights related to your personal information, please contact us at support@bookxchange.in.
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </div>
    </div>
  );
};

export default Privacy;
